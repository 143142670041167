@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body,
html {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #e7e5e4;
  color: #333;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.description {
  font-size: 10px;
  color: rgb(104, 104, 104);
}

.page-title {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: -30px;
  color: black;
}

.intro-text {
  text-align: left;
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 95vw;
  min-height: 95vh;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #e6e6e6;
  overflow: auto;
}

.section > div {
  flex: 1 1 100%;
}

.request-section,
.response-section {
  padding: 10px;
}

.request-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  border-right: 1px rgb(210, 210, 210) solid;
  padding-left: 10vw;
  padding-right: 40px;
  max-height: 100%;
}

.request-section input,
.request-section button {
  width: calc(100% - 40px);
  max-width: 600px;
  margin: 10px 0;
}

.request-section button {
  background-color: #ff5100;
  color: #ffffff;
  border: none;
  padding: 10px 0;
  font-size: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.request-section button:hover {
  background-color: #944607;
}

input,
.api-response-window {
  width: 100%;
  padding: 10px;
  font-size: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.api-response-window {
  background-color: #f3f2f1;
  color: #333;
  overflow-y: auto;
  height: 80vh;
  padding: 20px;
  margin: 10px 0;
  box-sizing: border-box;
  width: calc(100% - 20px);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-footer {
  background-color: #dadada;
  color: #7a7a7a;
  text-align: center;
  margin-top: -5px;
  font-size: 0.8rem;
}

.app-footer p {
  display: inline-block;
  padding: 10px 10px;
}

h2 {
  color: grey;
}

.error-message {
  color: rgb(138, 138, 138);
  font-size: 10px;
}

.app-footer a {
  color: rgb(35, 135, 197);
  text-decoration: none;
}

.toc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 60vw;
  margin: 0;
  padding: 20px;
  font-size: 0.8rem;
  background-color: white;
  border-radius: 5px;
}

.scrollable-content {
  overflow-y: auto;
  max-height: 90vh;
  width: 100%;
  padding: 10px;
}

.back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  text-decoration: none;
  color: white;
  background-color: #ff5100;
  border-radius: 5px;
  padding: 5px;
  transition: 0.5s;
}

.back-button:hover {
  background-color: #ce4201;
  transform: scale(0.95);
}

.download-button {
  text-decoration: none;
  background-color: #ff5100;
  color: white;
  padding: 10px;
  border-radius: 3px;
  margin-top: 50px;
  transition: 1s;
}

.download-button:hover {
  background-color: #c63f00;
  transform: scale(0.9);
}

.download-container {
  display: flex;
  justify-content: center;
}

.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60vw;
  text-align: center;
}

.success-thankyou {
  color: black;
}

.success-info {
  color: grey;
  margin-top: 30px;
}

.request-section ul {
  font-size: 10px;
  color: grey;
}

li {
  padding-bottom: 10px;
}

@media screen and (max-width: 440px) {
  .section {
    flex-direction: column;
  }

  .request-section,
  .response-section {
    flex-basis: auto;
    width: 100%;
    border: none;
  }

  .request-section {
    order: 1;
    height: auto;
    border-bottom: 1px solid rgb(179, 179, 179);
    margin-top: 100px;
  }

  .response-section {
    order: 2;
    margin-left: 20px;
  }

  .request-section > div {
    flex-direction: row;
    justify-content: space-between;
  }

  .request-section input,
  .request-section button {
    max-width: 70%;
    font-size: 8px;
  }

  .app-footer {
    position: relative;
    margin-top: -30px;
  }
}

@keyframes dotFlashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}
.loading-dots > div {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  animation: dotFlashing 1s infinite linear;
  animation-delay: 0s, 0.2s, 0.4s;
  margin-top: 30px;
}

.loading-dots div:nth-child(1) {
  animation-delay: 0s;
}
.loading-dots div:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-dots div:nth-child(3) {
  animation-delay: 0.4s;
}

.pdf-container {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

iframe {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.success-error-advice {
  margin-top: 30vh;
  color: grey;
}

.success-email {
  text-decoration: none;
  color: cornflowerblue;
}
