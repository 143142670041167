/* Ensure the CSS path matches your project structure */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.new-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e7e5e4;
  padding: 20px;
}

.welcome-section .home-title {
  margin-bottom: 10px;
  font-size: 22px;
}

.welcome-section {
  text-align: center;
  max-width: 70vw;
  margin-bottom: 30px;
}

.featured-recipes {
  text-align: center;
  max-width: 95vw;
  margin-bottom: 30px;
}

.intro-text {
  color: #7c7c7c;
  font-size: 11px;
  margin-bottom: 20px;
}

.start-button,
.recipe-link {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  text-decoration: none;
  background-color: #ff5100;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.start-button {
  color: white;
  margin-top: 60;
  margin-bottom: -20px;
  transition: 0.5s;
}

.start-button:hover {
  background-color: #e64a19;
  transform: scale(0.95);
}

.recipe-link:hover {
  background-color: #e64a19;
}

.recipe-link:hover .book-title,
.recipe-link:hover .date-created,
.recipe-link:hover .prompt {
  color: white;
}

.featured-recipes {
  overflow: hidden;
  padding: 20px 0;
  white-space: nowrap;
  position: relative;
}

.featured-recipes:before,
.featured-recipes:after {
  position: absolute;
  top: 0;
  width: 50px; /* Adjusted for your design */
  height: 100%;
  content: "";
  z-index: 2;
}

.featured-recipes:before {
  left: 0;
  background: linear-gradient(to left, rgba(245, 245, 245, 0), #e7e5e4);
}

.featured-recipes:after {
  right: 0;
  background: linear-gradient(to right, rgba(245, 245, 245, 0), #e7e5e4);
}

.featured-recipes:hover .scroll-container {
  animation-play-state: paused;
}

.scroll-container {
  display: inline-block;
  animation: slide 45s infinite linear;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.recipe-link {
  padding: 10px 20px;
  background-color: #ececec;
  border-radius: 5px;
  margin: 0 10px;
  text-align: left;
  width: 450px;
  height: 100px;
  box-shadow: 0.5px 0.5px 0.5px 0.5px rgb(172, 172, 172);
}

.example-recipes {
  color: rgb(108, 108, 108);
  margin-bottom: 10px;
}

.book-title {
  color: #333;
  font-size: 13px;
  margin-bottom: 10px;
}

.date-created {
  color: grey;
  border-bottom: solid 0.8px rgb(195, 195, 195);
  margin-bottom: 5px;
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.prompt {
  color: rgb(109, 109, 109);
  font-size: 11px;
}

.main-container {
  padding-bottom: 10px; /* Adjust this value according to your footer's height */
}

Footer {
  position: absolute;
  bottom: -70px;
  left: 0;
  width: 100%;
  padding: 20px;
}

.fade-in {
  animation: fadeIn 2s ease forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

img {
  max-width: 9vh;
  margin-left: 2px;
  margin-bottom: 50px;
  opacity: 0; /* Start fully transparent */
  transform: scale(0.95); /* Start at half the size */
  animation: fadeInScaleUp 1.2s ease-in forwards; /* Define the animation */
}

@keyframes fadeInScaleUp {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
